/**
 * 是否为微信浏览器
*/
export function isWeiXinBrowser(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    const matchArray: RegExpMatchArray | null = ua.match(/MicroMessenger/i);
    if (matchArray) {
        const code: string = matchArray[0];
        if(code == 'micromessenger'){
            return true;
        }
    }
	return false;
}