import FetchRequest from "@/api/FetchRequest";
import User from "@/mode/User";

/**
 * 用户接口
 */
class UserApi {
  static url = "/j2api/system/users";

  static getUser(id: string): Promise<any> {
	  console.log("getUser==>>",id);
    return FetchRequest.get("/j2api/system/user/" + id, true);
  }

  static currentUser(): Promise<any> {
    return FetchRequest.get("/j2api/system/user/profile", true);
  }

  static update(id: string, user: User): Promise<any> {
    user.id = id;
    return FetchRequest.put("/j2api/system/user/profile", JSON.stringify(user), true);
  }

  /**
   * search好友
   * @param mobile mobile
   */
  static search(mobile: string): Promise<any> {
    return FetchRequest.get("/j2api/system/user/search?mobile=" + mobile, true);
  }

  static updateUserPwd(oldPassword: string, newPassword: string): Promise<any> {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    return FetchRequest.put(
      this.url + "/updatePwd",
      JSON.stringify(data),
      true
    );
  }
}

export default UserApi;
